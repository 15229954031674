import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";
import { FormBuilders } from "@jauntin/reactables";
import { control, group } from "@reactables/forms";
import { Form, Field } from "@reactables/react-forms";
import { useReactable } from "@reactables/react";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import { RxMemberProp } from "Features/Members/Rx/RxMember";

const RxReactivateMemberForm = () =>
  FormBuilders.build(
    group({
      controls: {
        reactivationDate: control({
          initialValue: "",
          normalizers: ["normalizeDate"],
          validators: ["required", "validDateFormat", "dateNotInThePast"],
        }),
      },
    }),
    {
      name: "rxReactivateMemberForm",
      providers: formProviders,
    }
  );

const ModalReactivateMember = ({
  show,
  handleClose,
  rxMember,
}: {
  show: boolean;
  handleClose: () => void;
  rxMember: RxMemberProp;
}) => {
  const [
    {
      reactivateMember: { loading, error },
    },
    memberActions,
  ] = rxMember;

  useEffect(() => memberActions.reactivateMember.resetState, [memberActions]);

  const rxReactivateMemberForm = useReactable(RxReactivateMemberForm);
  const [state, actions] = rxReactivateMemberForm;

  if (!state) return;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="w-100 border-0 pb-0" closeButton />
      <Modal.Body className="px-4">
        <Form rxForm={[state, actions]}>
          <Field
            name="reactivationDate"
            component={TextField}
            label="Enter Reactivation Date"
            placeholder="mm/dd/yyyy"
            inputClassName="form-control-lg date-field"
            errorMessages={{
              dateFormat: "Incorrect date",
              dateNotInThePast: "The reactivation date must not be in the past",
            }}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4">
        <Button
          className="btn btn-outline-secondary"
          text="Cancel"
          onClick={handleClose}
        />
        <AsyncButton
          className="btn btn-primary px-4 mx-2 text-nowrap border-radius-0"
          spinning={loading}
          onClick={() => {
            memberActions.reactivateMember.send({
              reactivationDate: String(state.reactivationDate.value),
            });
          }}
          disabled={!state.reactivationDate.valid}
          spinningText="Activating..."
        >
          Reactivate Member
        </AsyncButton>
        {error && (
          <div className="form-error position-absolute mt--65">
            There was an error processing your request. Please try again.
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReactivateMember;
