import { Link } from "react-router-dom";
import { filter, map } from "rxjs/operators";
import { useReactable } from "@reactables/react";
import { Button } from "@jauntin/react-ui";
import { RxToggle } from "@jauntin/reactables";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Gender } from "@basicare/common/src/Constants/genders";
import { plans } from "@basicare/common/src/Constants/planTypes";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { RxMemberProp } from "Features/Members/Rx/RxMember";
import { EDIT_MEMBER_PAGE, getUrl, PROMO_CODE_PAGE } from "Helpers/URLParser";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";
import ModalCancelMember from "Features/Members/Components/ModalCancelMember";
import MemberStatus from "./MemberStatus";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";
import ModalReactivateMember from "./ModalReactivateMember";

const MemberView = ({ rxMember }: { rxMember: RxMemberProp }) => {
  const [
    {
      entity: { data: member },
      paymentMethodUpdateLink,
      cancelMember: { loading: cancellingMember },
    },
    actions,
    state$,
    memberActions$,
  ] = rxMember;

  const [
    showConfirmPaymentLink,
    { toggleOff: hideConfirmPaymentLink, toggleOn: openConfirmPaymentLink },
  ] = useReactable(RxToggle, false, {
    sources: [
      state$.pipe(
        filter(({ paymentMethodUpdateLink: { success, error } }) =>
          Boolean(success || error)
        ),
        map(() => ({ type: "toggleOff" }))
      ),
    ],
  });

  const RxCancelMemberModalToggle = () =>
    RxToggle(false, {
      sources: [memberActions$],
      reducers: {
        "Member - cancelMember - sendSuccess": () => false,
      },
    });

  const RxReactivateMemberModalToggle = () =>
    RxToggle(false, {
      sources: [memberActions$],
      reducers: {
        "Member - reactivateMember - sendSuccess": () => false,
      },
    });

  const [
    showCancelMemberNow,
    { toggleOff: hideCancelMemberNow, toggleOn: openCancelMemberNow },
  ] = useReactable(RxCancelMemberModalToggle);

  const [
    showModalCancel,
    { toggleOff: hideModalCancel, toggleOn: openModalCancel },
  ] = useReactable(RxCancelMemberModalToggle);

  const [
    showModalReactivate,
    { toggleOff: hideModalReactivate, toggleOn: openModalReactivate },
  ] = useReactable(RxReactivateMemberModalToggle);

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Member</h4>
          {member.status !== MemberStatuses.Cancelled && (
            <div className="d-flex float-right">
              <Link to={getUrl(EDIT_MEMBER_PAGE, member.id)}>
                <Button
                  text="Edit Member"
                  className="btn btn-primary px-4 mx-2 text-nowrap"
                  onClick={() => {}}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="content__body">
        <div className="mb-4">
          <span className="font-weight-bold">Status:</span>{" "}
          <span className="text-capitalize">
            <MemberStatus member={member} />
          </span>
        </div>
        <Card className="mb-4">
          <Card.Header className="bg-white font-weight-bold">
            Membership Information
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              {" "}
              {!member.recuroSubscriberNumber ||
                (!Boolean(
                  member.organization.requiresRecuroSubscriberNumber
                ) && (
                  <Col md={6}>
                    <span className="font-weight-bold">Member ID:</span>{" "}
                    {member.subscriberNumber}
                  </Col>
                ))}
              <Col md={6}>
                <span className="font-weight-bold">
                  Recuro Subscriber Number:
                </span>{" "}
                {member.recuroSubscriberNumber || ""}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Organization:</span>{" "}
                {member.organization.name}
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Product Code:</span>{" "}
                {member.organization.productCode}
              </Col>
            </Row>
            {member.scriptClaimGroupId && (
              <Row className="mb-3">
                <Col>
                  <span className="font-weight-bold">
                    Script Claim Group ID:
                  </span>{" "}
                  {member.scriptClaimGroupId}
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Elixir Group ID:</span>{" "}
                {member.organization.elixirGroupId}
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Recuro Group ID:</span>{" "}
                {member.organization.recuroGroupId}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Effective Date:</span>{" "}
                {member.effectiveDate}
              </Col>
              {member.cancellationDate && (
                <Col md={6}>
                  <span className="font-weight-bold">Cancellation Date:</span>{" "}
                  {member.cancellationDate}
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Header className="bg-white font-weight-bold">
            Primary Member
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              {" "}
              <Col md={6}>
                <span className="font-weight-bold">Primary Member:</span>{" "}
                {member.firstName} {member.lastName}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Date of Birth:</span>{" "}
                {member.dateOfBirth}
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Gender:</span>{" "}
                {member.gender === Gender.Male ? "Male" : "Female"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Phone Number:</span>{" "}
                {member.phone}
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Email:</span> {member.email}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Address:</span>{" "}
                {Object.keys(member.primaryAddress)
                  .filter((k) => k !== "timezone")
                  .filter((k) => Boolean(member.primaryAddress[k]))
                  .map((k) => member.primaryAddress[k])
                  .join(", ")}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {member.dependents.map(
          (
            {
              relationshipToAccountHolder,
              firstName,
              lastName,
              gender,
              dateOfBirth,
            },
            index
          ) => (
            <Card key={index} className="mb-4">
              <Card.Header className="bg-white font-weight-bold">
                Dependent #{index + 1}
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col md={6}>
                    <span className="font-weight-bold">Relationship:</span>{" "}
                    {relationshipToAccountHolder}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <span className="font-weight-bold">Dependent:</span>{" "}
                    {firstName} {lastName}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <span className="font-weight-bold">Date of Birth:</span>{" "}
                    {dateOfBirth}
                  </Col>
                  <Col md={6}>
                    <span className="font-weight-bold">Gender:</span>{" "}
                    {gender === Gender.Male ? "Male" : "Female"}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )
        )}
        <Card>
          <Card.Header className="bg-white font-weight-bold d-flex align-items-center">
            Payment Information
            {member.subscription && (
              <Button
                className="btn btn-primary px-4 text-nowrap border-radius-0 ml-auto"
                onClick={openConfirmPaymentLink}
                text="Send Payment Info Update Link"
              />
            )}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <span className="font-weight-bold">Payment Type:</span>{" "}
                  {member.paymentType}
                </div>
                {!member.isInTrial && member.subscription && (
                  <div className="mb-3">
                    <span className="font-weight-bold">Total Cost:</span>{" "}
                    {member.subscription
                      ? currencyFromInt(
                          plans.find(
                            ({ planType }) =>
                              planType === member.subscriptionInterval
                          ).price
                        ).formatDollars()
                      : "--"}
                  </div>
                )}
                <div className="mb-3">
                  <span className="font-weight-bold">
                    Subscription Interval:
                  </span>{" "}
                  <span className="text-capitalize">
                    {member.subscriptionInterval}
                  </span>
                </div>
                {member.subscription && (
                  <>
                    <div className="mb-3">
                      <span className="font-weight-bold">
                        Credit Card Number (Last Four):
                      </span>{" "}
                      <span className="text-capitalize">
                        {member.subscription.creditCardLastFour}
                      </span>
                    </div>
                    <div className="mb-3">
                      <span className="font-weight-bold">
                        Credit Card Type:
                      </span>{" "}
                      <span className="text-capitalize">
                        {member.subscription.creditCardType}
                      </span>
                    </div>
                    <div className="mb-3">
                      <span className="font-weight-bold">Name on Card:</span>{" "}
                      <span>{member.subscription.nameOnCard}</span>
                    </div>
                  </>
                )}
                {member.subscription?.paidThroughDate && (
                  <div className="mb-3">
                    <span className="font-weight-bold">Paid Through Date:</span>{" "}
                    <span className="text-capitalize">
                      {member.subscription.paidThroughDate}
                    </span>
                  </div>
                )}
              </Col>
              {member.promoCode && (
                <Col md={6}>
                  <div className="mb-3">
                    <span className="font-weight-bold">Promo Code Name:</span>{" "}
                    <Link to={getUrl(PROMO_CODE_PAGE, member.promoCode.id)}>
                      <Button
                        className="btn btn-link btn--edit text-left text-primary font-weight-bold"
                        text={member.promoCode.name}
                        onClick={() => {}}
                      />
                    </Link>
                  </div>
                  <div className="mb-3">
                    <span className="font-weight-bold">
                      Promo Code Discount:
                    </span>{" "}
                    {member.promoCode.type === PromoCodeTypes.Trial ||
                    member.promoCode.type === PromoCodeTypes.TrialPurchase
                      ? "Trial"
                      : member.promoCode.discount?.type ===
                        DiscountTypes.Percent
                      ? `${member.promoCode.discount?.amount}%`
                      : currencyFromInt(
                          member.promoCode.discount.amount
                        ).formatDollars()}
                  </div>
                  <div className="mb-3">
                    <span className="font-weight-bold">
                      Promo Code Interval:
                    </span>{" "}
                    {`${member.promoCode.startDate}-${
                      member.promoCode.endDate || "until end of subscription"
                    }`}
                  </div>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        <div className="my-3 d-flex justify-content-end">
          {!member.cancellationDate &&
            [MemberStatuses.Created].includes(member.status) && (
              <Button
                text="Cancel Member Now"
                onClick={openCancelMemberNow}
                className="btn btn-outline-secondary px-4 ml-2 text-nowrap"
              />
            )}
          {!member.cancellationDate &&
            [MemberStatuses.Active, MemberStatuses.Created].includes(
              member.status
            ) && (
              <Button
                text="Cancel Member"
                className="btn btn-outline-primary px-4 ml-2 text-nowrap"
                onClick={openModalCancel}
              />
            )}
          {member.cancellationDate &&
            MemberStatuses.Cancelled === member.status &&
            !member.subscription && (
              <Button
                text="Re-Activate"
                className="btn btn-outline-primary px-4 ml-2 text-nowrap"
                onClick={openModalReactivate}
              />
            )}
        </div>
      </div>
      <Modal
        show={
          paymentMethodUpdateLink.success ||
          Boolean(paymentMethodUpdateLink.error)
        }
        onHide={actions.paymentMethodUpdateLink.resetState}
        size="lg"
      >
        <Modal.Header className="w-100 border-0 pb-0" closeButton />
        <Modal.Body className="text-center px-5">
          <p>
            {paymentMethodUpdateLink.success
              ? "Success! An email has been sent to the member to update their payment information."
              : "There was an error processing your request. Please try again."}
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 mb-3">
          <Button
            className="btn btn-primary px-5"
            onClick={actions.paymentMethodUpdateLink.resetState}
            text="OK"
          />
        </Modal.Footer>
      </Modal>

      <ModalConfirmation
        show={showConfirmPaymentLink}
        handleClose={hideConfirmPaymentLink}
        message="The member will be sent an email that will allow them to update their payment information.  Proceed?"
        confirmBtnText="Yes"
        requireCheckbox={false}
        isUpdating={paymentMethodUpdateLink.loading}
        action={actions.paymentMethodUpdateLink.send}
      />

      {showCancelMemberNow && (
        <ModalConfirmation
          show={showCancelMemberNow}
          handleClose={hideCancelMemberNow}
          message="Are you sure you want to cancel the member before activation?"
          confirmBtnText="Cancel Member"
          cancelBtnText="Discard"
          isUpdating={cancellingMember}
          requireCheckbox={false}
          action={() => actions.cancelMember.send({})}
        />
      )}

      {showModalCancel && (
        <ModalCancelMember
          show={showModalCancel}
          handleClose={hideModalCancel}
          rxMember={rxMember}
        />
      )}

      {showModalReactivate && (
        <ModalReactivateMember
          show={showModalReactivate}
          handleClose={hideModalReactivate}
          rxMember={rxMember}
        />
      )}
    </>
  );
};

export default MemberView;
